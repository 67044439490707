







































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";
import "swiper/swiper-bundle.min.css";

export default Vue.extend({
  data() {
    return {
      scrollTop: 0,
      swiperOptions: {
        speed: 600,
        shortSwipes: false,
        longSwipesMs: 50,
        longSwipesRatio: 0.05
      }
    };
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss",
      url: "url"
    }),
    backgroundPosition(): string {
      return "center " + this.scrollTop * 1.5 + "px";
    }
  },
  methods: {
    onScroll() {
      this.scrollTop = document.documentElement.scrollTop;
    },
    title(title: string): string {
      if (this.isMobile) {
        return title;
      } else {
        return title.replace("<br />", "");
      }
    },
    onDownload() {
      download(this.oss.pdf_cyberboard_terminal);
    },
    onVerify() {
      window.open(this.$paths.verify + "cyberboard/", "_blank");
    }
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.onScroll);
  }
});
